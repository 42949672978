import { Typography } from '@mui/material'
import React from 'react'

const MonthBalance = ({balanceForThisMonth}) => {
  return (
    <>
    <Typography color={"#441d45b1"} fontWeight={600}>
      今月の収支：{Number(balanceForThisMonth).toLocaleString()}円
    </Typography>
    </>
  )
}

export default MonthBalance
