import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const LogoutButton = () => {

    const navigate = useNavigate();


  const logoutHandler = () => {
    const answer = window.confirm("本当にログアウトしますか？");

    if (answer) {
        localStorage.removeItem("user");
        navigate("/login");
    }
  };

  const moveLoginPage = () => {
    setTimeout(() => {
      navigate("/login");
    }, 500); 
  }

  return (
    localStorage.getItem("user")?
    <Button color={"error"} variant={"outlined"} value="ログアウト" onClick={logoutHandler} >ログアウト</Button>
    :
    <Button color={"primary"} variant={"outlined"} value="ログイン" onClick={moveLoginPage} >ログイン</Button>
  )
};

export default LogoutButton;
