import { Close } from "@mui/icons-material";
import { Button, IconButton, Snackbar } from "@mui/material";

import React, { useState } from "react";

const SnackBar = ({ open, setOpen }) => {


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Snackbar
      open={open}
      autoHideDuration={1000}
      onClose={handleClose}
      message="追加しました。"
      action={action}
    />
  );
};

export default SnackBar;
