import { InputAdornment, TextField } from "@mui/material";
import React from "react";

const MuiInput = ({
  width,
  type,
  label,
  endAdornment,
  stateData,
  setStateData,
}) => {
  return (
    <TextField
      required
      size={"small"}
      sx={{ width: { width } }}
      type={type}
      label={label}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ),
      }}
      value={stateData}
      onChange={(e) => setStateData(e.target.value)}
    ></TextField>
  );
};

export default MuiInput;
