import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
// import firebase from "firebase"
import LoginPage from "./Pages/LoginPage";
import HomePage from "./Pages/HomePage";
import NotFound from "./Pages/NotFound";

import { useState } from "react";
import RegistPage from "./Pages/RegistPage";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />}> </Route>
        <Route path="/login" element={<LoginPage setIsLoggedIn={setIsLoggedIn} />}></Route>
        <Route path="/regist" element={<RegistPage />}></Route>
        {/* ページがない場合 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
