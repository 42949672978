import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../../utils/Firebase";

import {
  EGENRUS as eGenrus,
  IGENRUS as iGenrus,
  incomeAndExpenditure as iae,
} from "./../../../utils/constant";
import MuiSelect from "./atoms/Input/MuiSelect";
import MuiInput from "./atoms/Input/MuiInput";
import MuiOptgrpSelect from "./atoms/Input/MuiOptgrpSelect";
import MuiButton from "../atoms/MuiButton";

const InputData = ({ setOpen, setDatas,setBalanceForThisMonth }) => {
  let today = "";
  const [incomeAndExpenditure, setIncomeAndExpenditure] = useState("支出");
  const [genru, setGenru] = useState("");
  const [money, setMoney] = useState("");
  const [detail, setDetail] = useState("");
  const [date, setDate] = useState(today);

  /*************************************
   * 初回ロード時、今日の日付を取得する
   **************************************/
  useEffect(() => {
    const getTodayFunc = () => {
      const today = new Date();
      const yyyy = today.getFullYear();
      const mm = ("0" + (today.getMonth() + 1)).slice(-2);
      const dd = ("0" + today.getDate()).slice(-2);
      return yyyy + "-" + mm + "-" + dd;
    };
    today = getTodayFunc();
    setDate(today);
  }, []);

  /*************************************
   * Firebase登録後入力値を初期値に戻す
   *************************************/
  const inputValueInitialization = () => {
    setIncomeAndExpenditure("支出");
    setGenru("交際費");
    setMoney("");
    setDetail("");
  };

  /*************************************
   * 入力欄の初期化処理
   * Firebase登録処理
   * データの更新処理
   *************************************/
  const submitEvent = async (e) => {
    // setDatas([]);
    await e.preventDefault();

    const createData = {
      iae: incomeAndExpenditure,
      genru: genru,
      money: money,
      day: date,
      detail: detail,
      uuid: uuidv4(),
      userId: localStorage.getItem("user"),
    };

    // fireStoreの登録処理
    const registData = async () => {
      await addDoc(collection(db, "input_value"), createData);
    };

    await registData();
    await inputValueInitialization();

    // 表データの更新が必要
    // await updateData();
    await setDatas(prev => [...prev,createData]);

     // 後で処理をメソッドに書き出す(非同期アップデート完了)
     switch (incomeAndExpenditure) {
      case "支出":
        setBalanceForThisMonth(prev => prev -= +money);
        break;
      case "収入":
        setBalanceForThisMonth(prev => prev += +money);
        break;
      default:
        return;
    }
  


    // 登録完了の表示
    await setOpen(true);
  };

  return (
    <Form onSubmit={submitEvent}>
      <MuiInput
        width={150}
        type={"date"}
        label={"日時"}
        stateData={date}
        setStateData={setDate}
      />

      <MuiSelect
        labelLang={"収支"}
        labelId={"incomeAndExpenditure_label"}
        mapDatas={iae}
        minWidth={100}
        selectId={"incomeAndExpenditure"}
        stateData={incomeAndExpenditure}
        setStateData={setIncomeAndExpenditure}
        setGenru={setGenru}
      />

      <MuiOptgrpSelect
        // マップデータの切り替えのために必要
        incomeAndExpenditure={incomeAndExpenditure}
        labelLang={"ジャンル"}
        labelId={"incomeAndExpenditure_label"}
        mapDatas={eGenrus}
        mapDatas2={iGenrus}
        minWidth={180}
        selectId={"incomeAndExpenditure"}
        stateData={genru}
        setStateData={setGenru}
      />

      <MuiInput
        width={150}
        type={"number"}
        label={"金額"}
        endAdornment={"円"}
        stateData={money}
        setStateData={setMoney}
      />

      <MuiInput
        width={250}
        type={"input"}
        label={"詳細"}
        stateData={detail}
        setStateData={setDetail}
      />

      <MuiButton value={"追加"} variant={"outlined"} type={"submit"} />
    </Form>
  );
};

export default InputData;

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
