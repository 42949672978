// import  Input  from "../atoms/Input";
// import styled from "styled-components";

// const SignupForm = ({ navigate, auth, createUserWithEmailAndPassword }) => {
//   const newRegistrationHandler = (event) => {
//     event.preventDefault();

//     const email = event.target[0].value;
//     const password = event.target[1].value;
//     const confirm = event.target[2].value;

//     if (password === confirm) {
//       // パスワードと確認用パスワードが一致していた場合に登録処理を行う
//       createUserWithEmailAndPassword(auth, email, password)
//         .then((userCredential) => {
//           // 登録完了ページに遷移させる
//           navigate("/regist");
//         })
//         .catch((error) => {
//           if (error.code === "auth/invalid-email") {
//             //メールアドレスの形式がおかしい
//             alert("メールアドレスの形式がおかしい");
//           } else if (error.code === "auth/user-disabled") {
//             // ユーザが無効になっている
//             alert("ユーザが無効になっている");
//           } else if (error.code === "auth/user-not-found") {
//             // ユーザが存在しない
//             alert("ユーザが存在しない");
//           } else if (error.code === "auth/wrong-password") {
//             // パスワードが間違っている
//             alert("パスワードが間違っている");
//           } else if (error.code === "auth/too-many-requests") {
//             // 何度もパスワードを間違えた
//             alert("何度もパスワードを間違えた");
//           } else if (error.code === "auth/email-already-in-use") {
//             // すでに登録済みのメールアドレス
//             alert("すでに登録済みのメールアドレスです");
//           }
//           return;
//         });
//     } else {
//       return alert("パスワードが一致してません");
//     }
//   };

//   return (
//     <form onSubmit={newRegistrationHandler}  style={{ textAlign: "center" , marginBottom:"50px"}}>
//       <h2 style={{ marginBottom:"30px" , color:"#615ca2"}}>新規登録</h2>
//         <Input type="email" id="email" name="email" placeholder="Email" required />
//         <Input type="password" id="password" name="password" placeholder="password" required />
//         <Input type="password" id="confirm" name="confirm" placeholder="password(確認用)" required />
//       <Button type="submit">新規登録</Button>
//     </form>
//   );
// };

// export default SignupForm;

// const Button = styled.button`
//   margin-top: 20px;
//   padding: 10px 20px;
//   background-color: #0077c2;
//   color: #fff;
//   border: none;
//   border-radius: 4px;
//   font-size: 16px;
// `;


import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";

const SignupForm = ({ navigate, auth, createUserWithEmailAndPassword }) => {
  const newRegistrationHandler = (event) => {
    event.preventDefault();

    const email = event.target[0].value;
    const password = event.target[1].value;
    const confirm = event.target[2].value;

    if (password === confirm) {
      // パスワードと確認用パスワードが一致していた場合に登録処理を行う
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // 登録完了ページに遷移させる
          navigate("/regist");
        })
        .catch((error) => {
          if (error.code === "auth/invalid-email") {
            //メールアドレスの形式がおかしい
            alert("メールアドレスの形式がおかしい");
          } else if (error.code === "auth/user-disabled") {
            // ユーザが無効になっている
            alert("ユーザが無効になっている");
          } else if (error.code === "auth/user-not-found") {
            // ユーザが存在しない
            alert("ユーザが存在しない");
          } else if (error.code === "auth/wrong-password") {
            // パスワードが間違っている
            alert("パスワードが間違っている");
          } else if (error.code === "auth/too-many-requests") {
            // 何度もパスワードを間違えた
            alert("何度もパスワードを間違えた");
          } else if (error.code === "auth/email-already-in-use") {
            // すでに登録済みのメールアドレス
            alert("すでに登録済みのメールアドレスです");
          }
          return;
        });
    } else {
      return alert("パスワードが一致してません");
    }
  };

  return (
    <>
      <h2 style={{ color: "#615ca2" }}>新規登録</h2>
      <Box sx={{ textAlign: "center", display: "flex", flexDirection: "column", gap: 1 }}>
        <TextField label="Email" type="email" id="email" name="email" variant="outlined" margin="dense" required />
        <TextField label="Password" type="password" id="password" name="password" variant="outlined" margin="dense" required />
        <TextField label="Password(確認用)" type="password" id="confirm" name="confirm" variant="outlined" margin="dense" required />
        <Button onClick={newRegistrationHandler} variant="contained" type="submit" sx={{ color: "#fff", backgroundColor: "#0077c2", borderRadius: "4px", fontSize: "16px" }}>新規登録</Button>
      </Box>
    </>
  );
};

export default SignupForm;