
import { Box, Button, TextField } from "@mui/material";

const LoginForm = ({
  navigate,
  auth,
  provider,
  signInWithPopup,
  signInWithEmailAndPassword,
  setIsLoggedIn,
}) => {
  const loginWithGoogle = () => {
    /*******************************************
     *  Google認証ログインを行う
     *******************************************/
    signInWithPopup(auth, provider)
      .then((result) => {
        localStorage.setItem("user", result.user.uid);
        setIsLoggedIn(true);

        navigate("/");
      })
      .catch((error) => {});
  };

  const loginWithEmailAndPassword = (event) => {
    event.preventDefault();
    const email = event.target[0].value;
    const password = event.target[1].value;

    const firebaseLogin = async () => {
      await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          localStorage.setItem("user", userCredential.user.uid);
          setIsLoggedIn(true);

          navigate("/");
        })
        .catch((error) => {
          const errorCode = error.code;
          // ログインできない場合はバリデーション処理なんかを実現できる
          const errorMessage = error.message;
          alert(errorMessage);
        });
    };

    firebaseLogin();
  };

  return (
    <>
      <form
        onSubmit={loginWithEmailAndPassword}
        style={{ textAlign: "center" }}
      >
          <h2 style={{ marginBottom: "10px", color: "#615ca2" }}>ログイン</h2>
        <Box sx={{display:"flex" ,flexDirection:"column", gap:1}}>
          <TextField label="Email" variant="outlined" type="email" />
          <TextField label="password" variant="outlined" type="password" />

          <Button type="submit" variant="contained" sx={{ mt: 2 }}>
            ログイン
          </Button>
        </Box>
      </form>
      <p style={{ marginTop: "50px" }}>Googleアカウントでログインを行う</p>
      {/* FireBaseで認証を行うイベントを登録する */}
      <Button
        onClick={loginWithGoogle}
        variant="contained"
        color="secondary"
        sx={{ mt: 2 }}
      >
        Googleアカウントでログイン
      </Button>
    </>
  );
};

export default LoginForm;
