import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";

import TablePaginationComponent from "./components/NewHistory/TablePaginationComponent";
import FormControlLabelComponent from "./components/NewHistory/FormControlLabelComponent";
import EnhancedTableHeadComponent from "./components/NewHistory/EnhancedTableHeadComponent";
import TableBodyCompoment from "./components/NewHistory/TableBodyComponent";
import EnhancedTableToolbarComponent from "./components/NewHistory/EnhancedTableToolbarComponent";

const EnhancedTable = ({datas}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  return (
    <Box sx={{ width: "100%" }}>
      {/* 切り離し完了　正常動作確認済
      <FormControlLabelComponent
        label={"表示切替"}
        dense={dense}
        setDense={setDense}
      /> */}

      
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbarComponent numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            
            <EnhancedTableHeadComponent
              numSelected={selected.length}
              order={order}
              setOrder={setOrder}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              selected={selected}
              setSelected={setSelected}
              rows={datas}
            />

{/* 機能としては整備完了　細かい修正は後日実施
ソート機能も健在のままデプロイ出来た */}
            <TableBodyCompoment
              order={order}
              orderBy={orderBy}
              selected={selected}
              page={page}
              dense={dense}
              rowsPerPage={rowsPerPage}
              rows={datas}
              setSelected={setSelected}
            />
          </Table>
        </TableContainer>

        {/* 切り離し完了　正常動作を確認済 */}
        <TablePaginationComponent
          rows={datas}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export default EnhancedTable;


function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Honeycomb", 408, 3.2, 87, 6.5),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Jelly Bean", 375, 0.0, 94, 0.0),
  createData("KitKat", 518, 26.0, 65, 7.0),
  createData("Lollipop", 392, 0.2, 98, 0.0),
  createData("Marshmallow", 318, 0, 81, 2.0),
  createData("Nougat", 360, 19.0, 9, 37.0),
  createData("Oreo", 437, 18.0, 63, 4.0),
  createData("Cupcake1", 305, 3.7, 67, 4.3),
  createData("Cupcake2", 305, 3.7, 67, 4.3),
  createData("Cupcake3", 305, 3.7, 67, 4.3),
  createData("Cupcake4", 305, 3.7, 67, 4.3),
  createData("Cupcake5", 305, 3.7, 67, 4.3),
  createData("Cupcake6", 305, 3.7, 67, 4.3),
  createData("Cupcake7", 305, 3.7, 67, 4.3),
  createData("Cupcake8", 305, 3.7, 67, 4.3),
  createData("Cupcake9", 305, 3.7, 67, 4.3),
  createData("Cupcake10", 305, 3.7, 67, 4.3),
  createData("Cupcake11", 305, 3.7, 67, 4.3),
  createData("Cupcake12", 305, 3.7, 67, 4.3),
  createData("Cupcake13", 305, 3.7, 67, 4.3),
  
];
