import React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";

const TableBodyCompoment = ({order,orderBy,selected,page,dense,rowsPerPage,rows,setSelected}) => {
  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
  // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
  // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
  // with exampleArray.slice().sort(exampleComparator)
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

// 空の行がある最後のページに到達したときに、レイアウトジャンプを回避する。
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


    // ソートを変更した時に名前の順番を変更する
    const isSelected = (name) => {
      return selected.indexOf(name) !== -1
    };


    // 昇順か降順かを判定する
    // またorderByには選択しているセルの名称が格納されている
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

// getComparatorで使用している。特に調査の必要なし
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }


  /********************************************
   * 配列操作(主にチェック判定)の各動作、selectedにセットを行う
   * @param selected : 現在選択された要素を格納している(useState)
   * @param newSelected : シャローコピーで作成された配列
   *********************************************/
  const handleClick = (name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    // 追加を行う(チェック付与)
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    // 追加した要素を削除(チェックを外す)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      // 詳細不明
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      console.log(newSelected);
      // 詳細不明
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };





  return (
    <TableBody>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          const isItemSelected = isSelected(row.uuid);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow
              hover
              onClick={() => handleClick( row.uuid)}
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.uuid}
              selected={isItemSelected}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={isItemSelected}
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </TableCell>
              {/* <TableCell component="th" id={labelId} scope="row" padding="none">
                {row.name}
              </TableCell>
              <TableCell align="left">{row.calories}</TableCell>
              <TableCell align="left">{row.fat}</TableCell>
              <TableCell align="left">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell> */}

              <TableCell component="th" id={labelId} scope="row" padding="none">
                {row.detail}
              </TableCell>
              <TableCell align="left">{Number(row.money).toLocaleString()}円</TableCell>
              <TableCell align="left">{row.iae}</TableCell>
              <TableCell align="left">{row.genru}</TableCell>
              <TableCell align="left">{row.day}</TableCell>
              

              
            </TableRow>
          );
        })}
      {emptyRows > 0 && (
        <TableRow
          style={{
            height: (dense ? 33 : 53) * emptyRows,
          }}
        >
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  );
};

export default TableBodyCompoment;
