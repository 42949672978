import React from 'react'

const NotFound = () => {
  return (
    <div>
      404 NOT FOUND
    </div>
  )
}

export default NotFound
