import { signInWithPopup } from "firebase/auth";
import React, { useState } from "react";
import styled from "styled-components";
import { auth, provider } from "../utils/Firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import LoginForm from "./components/Auth/LoginForm";
import SignupForm from "./components/Auth/SignupForm";

const LoginPage = ({setIsLoggedIn}) => {
  const [tab, setTab] = useState("login");
  const navigate = useNavigate();

  const handleTabChange = (newTab) => {
    setTab(newTab);
  };

  return (
    <Container>
      <TabButtonWrapper>
        <TabButton
          active={tab === "login"}
          onClick={() => handleTabChange("login")}
        >
          ログイン
        </TabButton>
        <TabButton
          active={tab === "signup"}
          onClick={() => handleTabChange("signup")}
        >
          新規登録
        </TabButton>
      </TabButtonWrapper>

      {tab === "login" ? (
        <LoginForm
          auth={auth}
          provider={provider}
          navigate={navigate}
          signInWithEmailAndPassword={signInWithEmailAndPassword}
          signInWithPopup={signInWithPopup}
          setIsLoggedIn={setIsLoggedIn}
        />
      ) : (
        <SignupForm
          auth={auth}
          navigate={navigate}
          createUserWithEmailAndPassword={createUserWithEmailAndPassword}
        />
      )}
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius:0 0 10px 10px;
  transform: translate(-50%, -50%);
  flex-direction: column; // 縦並び（上から下に）
  align-items: center;
  justify-content: center;
  width: 360px;
  height: 450px;
  padding-bottom: 30px;
  background-color: #ffffff8c;
  box-shadow: -1px 8px 22px -8px #1d1c1c;
`;

const TabButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: auto;
`;

const TabButton = styled.button`
  padding: 10px 54px;
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => (props.active ? "#615ca2" : "#ffffff9d")};
  background-color: ${(props) => (props.active ? "#c4c4c412" : "#ffffff45")};
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${(props) => (props.active ? "#ffffff4e" : "#ffffff18")};
  }
`;
export default LoginPage;
