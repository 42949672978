
// 課題：全ての情報を取得している。今度の運用が月ごとのデータを表示する為、その際には変更が必要になる
// 処理側で取得する条件に年月日を指定したら簡単にできそう...
export function RetrieveAllDataForLoggedInUser(query,collection,where,db) {
    const q = query(
        collection(db, "input_value"),
        where("userId", "==", localStorage.getItem("user"))
      );
      return q;
}

export function calculateBalance(data) {
    const income = {};
    const expenses = {};
    
    // 収入と支出を分類する
    Object.values(data).forEach(item => {
      if (item.iae === "収入") {
        if (income[item.genru]) {
          income[item.genru] += parseInt(+item.money);
        } else {
          income[item.genru] = parseInt(+item.money);
        }
      } else {
        if (expenses[item.genru]) {
          expenses[item.genru] += parseInt(+item.money);
        } else {
          expenses[item.genru] = parseInt(+item.money);
        }
      }
    });
  
    // 同じ種類の収支を合計する
    const incomeTotal = Object.values(income).reduce((total, val) => total + val, 0);
    const expensesTotal = Object.values(expenses).reduce((total, val) => total + val, 0);
    
  
    // 収入と支出の差額を計算する
    const difference = incomeTotal - expensesTotal;
  
    // 差額を返す
    return difference;
  }


// データを扱いやすい形式に変更する
export async function DataFormatChangeForUserInputAllData(getDocs, FirebaseFetchData) {
    const querySnapshot = await getDocs(FirebaseFetchData);

    const data = [];
    querySnapshot.forEach((doc) => {
      const docDatas = doc.data();
      data.push(docDatas);
    });
    return data;
  }



  export const CreationOfDataForGraphs = {
    moneyAndGenru : function (datas,){
        return datas.map((data) => [data.genru, data.money]);
        
    },
    genru : function (data) {
        return [...new Set(data.map((d) => d.genru))];
        
    },
    calc: function(data){
        return data.reduce((acc, { genru, money }) => {
            const key = genru;
            const value = Number(money);
      
            if (key in acc) {
              acc[key] += value;
            } else {
              acc[key] = value;
            }
      
            return acc;
          }, {});

    }
  }