import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const MuiSelect = ({
  labelLang,
  labelId,
  mapDatas,
  minWidth,
  selectId,
  stateData,
  setStateData,
  setGenru,
}) => {
  return (
    <Box sx={{ minWidth: { minWidth } }}>
      <FormControl fullWidth size={"small"}>
        <InputLabel id={labelId}>{labelLang}</InputLabel>
        <Select
          required
          label={labelLang}
          labelId={labelId}
          id={selectId}
          value={stateData}
          onChange={(e) => {
            setStateData(e.target.value);
            // ジャンルを選択中に収支を変更した場合に出力されるエラーに対策するため
            // 支出のジャンル文字列が選択された状態で収入に変更すると支出の文字列が収入の選択欄ないとエラーが発生する
            setGenru("");
          }}
        >
          {mapDatas.map((value) => {
            return (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default MuiSelect;
