import React from "react";
import { Button } from "@mui/material";

const MuiButton = ({ value, variant, type = "button", color = "primary", event }) => {
  return (
    <Button color={color} type={type} variant={variant} >
      {value}
    </Button>
  );
};

export default MuiButton;
