import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const MuiOptgrpSelect = ({
  incomeAndExpenditure,
  labelLang,
  labelId,
  mapDatas,
  // 何か新しい名称を考える
  mapDatas2,
  minWidth,
  optGroups,
  selectId,
  stateData,
  setStateData,
}) => {
  return (
    <Box sx={{ minWidth: { minWidth } }}>
      <FormControl fullWidth size={"small"}>
        <InputLabel id={labelId}>{labelLang}</InputLabel>
        <Select
        required
          label={labelLang}
          labelId={labelId}
          id={selectId}
          value={stateData}
          onChange={(e) => setStateData(e.target.value)}
        >

          {incomeAndExpenditure === "支出" ? 
          mapDatas.map((value)=> {
            return (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            );
          })
         :
         mapDatas2.map((value)=> {
          return (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          );
        })
      }
        </Select>
      </FormControl>
    </Box>
  );
};

export default MuiOptgrpSelect;
