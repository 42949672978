import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import Header from "./components/Header/Header";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import NewHistory from "./components/Home/NewHistory";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../utils/Firebase";
import SnackBar from "./components/Header/atoms/SnackBar/SnackBar";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";

import {
  RetrieveAllDataForLoggedInUser,
  DataFormatChangeForUserInputAllData,
  CreationOfDataForGraphs,
  calculateBalance,
} from "../utils/firebaseMethods";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [datas, setDatas] = useState([]);
  const [balanceForThisMonth, setBalanceForThisMonth] = useState(parseInt(0));
  const [open, setOpen] = useState(false);
  const [moneyAndGenruDataForGraph, setMoneyAndGenruDataForGraph] = useState([]);
  const [genreDataLabellForGraph, setgenreDataLabellForGraph] = useState([]);
  const [aggregationData, setAggregationData] = useState({});
  const [keyValues, setKeyValues] = useState([]);
console.log(aggregationData)

  const Navigate = useNavigate();

  ChartJS.register(ArcElement, Tooltip, Legend);

  const data = {
    labels: genreDataLabellForGraph,
    datasets: [
      {
        label: "# of Votes",
        data: keyValues,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 2,
        hoverOffset: 5,
      },
    ],
  };
  /*********************************************
 *        datasに更新があった場合             *
 *********************************************/
useEffect(() => {
  const fetchUserData = async () => {
    setMoneyAndGenruDataForGraph(CreationOfDataForGraphs.moneyAndGenru(datas));
    setgenreDataLabellForGraph(CreationOfDataForGraphs.genru(datas));
    const newAggregationData = await CreationOfDataForGraphs.calc(datas);
    const { 給与, ...newData } = newAggregationData;
    setAggregationData(newData);
    setKeyValues(Object.values(newData));
    setBalanceForThisMonth(calculateBalance(datas));
  }
  fetchUserData();
},  [datas]);

  /*********************************************
   *                初回ロード時                *
   *********************************************/
  useEffect(() => {
    // ログインユーザーのデータを取得する
    const fetchUserData = async () => {
      if (!localStorage.getItem("user")) {
        // ログインしていないユーザーに対してログインページへ強制的に遷移させる
        Navigate("/login");
        return;
      }
  
      const userInputs = await RetrieveAllDataForLoggedInUser(query, collection, where, db);
      const data = await DataFormatChangeForUserInputAllData(getDocs, userInputs);
      setDatas(data);
      setMoneyAndGenruDataForGraph(CreationOfDataForGraphs.moneyAndGenru(data));
      setgenreDataLabellForGraph(CreationOfDataForGraphs.genru(data));
      const newAggregationData = await CreationOfDataForGraphs.calc(data);
      setAggregationData(newAggregationData);
      setKeyValues(Object.values(newAggregationData));
      setBalanceForThisMonth(calculateBalance(data))
    };
    fetchUserData();
  }, []);



  return (
    <>
      <Header
        setOpen={setOpen}
        setDatas={setDatas}
        datas={datas}
        setBalanceForThisMonth={setBalanceForThisMonth}
        balanceForThisMonth={balanceForThisMonth}
      />

      <SnackBar open={open} setOpen={setOpen} />

{/* とりあえずチャート部分をべた書きしていく */}
      <Grid container sx={{}} justifyContent="center"  mt={1}>
{/* ======================================================================= */}
        <Grid item xs={10} md={3} sx={{ boxShadow: 3, borderRadius: 1, backgroundColor: "white", mr:2}} >
          {/* <Box sx={{width: "100%",height: "400",}}> */}
            <Box sx={{width: "80%", height: "300",p: 1, margin: "0 auto"}} >
              <Doughnut data={data} />
            </Box>
            <Box sx={{width: "100%",  height: "100%",  p:1 }} >
              <SummaryTable aggregationData={aggregationData}> </SummaryTable>
            </Box>
          {/* </Box> */}
        </Grid>
 {/* ======================================================================= */}
        <Grid item xs={10} md={8}>
          <NewHistory datas={datas} />
        </Grid>
 {/* ======================================================================= */}
      </Grid>
    </>
  );
};

export default Home;


export const SummaryTable = ({ aggregationData }) => {
  return (
    <TableContainer component={Paper} sx={{ maxWidth: "300px", margin: "0 auto",backgroundColor: "#f0f0f0" }}>
      <Table aria-label="summary table">
        <TableHead>
          <TableRow>
            <TableCell>種別</TableCell>
            <TableCell align="right">合計金額</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(aggregationData).map((data) => {
            return (
              <TableRow key={data[0]}>
                <TableCell component="th" scope="row">
                  {data[0]}
                </TableCell>
                <TableCell align="right">{Number(data[1]).toLocaleString()}円</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

