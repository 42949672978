// 必要なSDKから必要な機能をインポート
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: 使用したいFirebase製品のSDKを追加する
import { getAuth, GoogleAuthProvider } from "firebase/auth";


// https://firebase.google.com/docs/web/setup#available-libraries

// ウェブアプリのFirebaseの設定
const firebaseConfig = {
  apiKey: "AIzaSyBxlYkUOTvBEXNO4JHBrfqphhzpPd-Cu3I",
  authDomain: "household-account-book-b91f6.firebaseapp.com",
  projectId: "household-account-book-b91f6",
  storageBucket: "household-account-book-b91f6.appspot.com",
  messagingSenderId: "215039321835",
  appId: "1:215039321835:web:7d87da79b869661a3940ae"
};

// Firebaseの初期化
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

export {auth, provider, db};