import React from 'react'
import { Link } from 'react-router-dom'

const RegistPage = () => {
  return (
    <div>
      <h3>登録が完了しました</h3>
      <Link to={"/login"}>ログインページに戻る</Link>
    </div>
  )
}

export default RegistPage
