import React from "react";
import styled from "styled-components";
import InputData from "./InputData";
import LogoutButton from "./LogoutButton";
import MonthBalance from "./MonthBalance";

const Header = ({setOpen, setDatas, datas , setBalanceForThisMonth,balanceForThisMonth}) => {
  return (
    <HeaderWrapper>
      <MonthBalance balanceForThisMonth={balanceForThisMonth}/>
      <InputData setOpen={setOpen} setDatas={setDatas} datas={datas} setBalanceForThisMonth={setBalanceForThisMonth} balanceForThisMonth={balanceForThisMonth}/>
      <LogoutButton />
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.div`
  width: 100%;
  padding: 10px 25px;
  background-color: #ffffff72;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* padding-right: 30px; */

  /* ナンバーの矢印を削除 */
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

`;